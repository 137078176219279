import React, { useCallback, useEffect, useRef, useState } from "react"
import { breakpoints, media } from "src/styles/breakpoints"
import { graphql, useStaticQuery } from "gatsby"
import styled, { css } from "styled-components"

import Container from "src/components/Container"
import FlexibleHeading from "src/components/FlexibleHeading"
import Link from "src/components/Link"
import Media from "src/components/Media"
import { articleNormalizer } from "src/utils/contentNormalizers"
import { get } from "lodash"
import { getSizes } from "src/utils/images"

export default ({ onBgChange }) => {
  const { wordpressAcfOptions } = useStaticQuery(graphql`
    query SlideshowQuery {
      wordpressAcfOptions {
        options {
          home_slides_timeout
          home_slides {
            slide_alternative_text
            slide_article {
              ... on wordpress__POST {
                slug
                title
                excerpt
                path
                date(formatString: "DD MMMM YYYY")
                author {
                  name
                  path
                }
                category {
                  name
                  path
                }
                featured_media {
                  ...MediaFragment
                }
                acf {
                  article_collaboration
                  article_custom_link_url
                  featured_image_tint
                }
              }
            }
          }
        }
      }
    }
  `)

  const items = wordpressAcfOptions.options.home_slides.map(el => {
    return {
      article: articleNormalizer(el.slide_article),
      alternativeText: el.slide_alternative_text,
    }
  })

  const [currentIndex, setCurrentIndex] = useState(0)
  const {
    title,
    author,
    category,
    featured_media,
    url,
    urlTarget,
    acf: { article_collaboration, featured_image_tint },
  } = items[currentIndex].article
  const { alternativeText } = items[currentIndex]
  const showAlternativeText = !featured_media && alternativeText
  const imageSharp = get(
    items[currentIndex].article.featured_media,
    "source_url"
  )
  const timerRef = useRef(null)
  const timeout = Number.isInteger(
    wordpressAcfOptions.options.home_slides_timeout
  )
    ? wordpressAcfOptions.options.home_slides_timeout
    : 5000

  onBgChange(showAlternativeText ? "light" : "dark")

  const loadImagesIntoMemory = useCallback(items => {
    if (
      typeof window === "undefined" ||
      window.innerWidth < breakpoints.desktop
    ) {
      return
    }
    // Load all slides so user don't have to wait for images when he changes a slide
    items.forEach(item => {
      const image = new window.Image()
      const { source_url } = item.article.featured_media || {}
      image.src = source_url
      // image.srcset = srcSet
    })
  }, [])

  function changeSlide(index) {
    setCurrentIndex(index)
    if (timeout > 0) {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
      timerRef.current = setTimeout(() => {
        changeSlide(index + 1 >= items.length ? 0 : index + 1)
      }, timeout)
    }
  }

  useEffect(() => {
    if (timeout > 0) {
      changeSlide(0)
    }
    loadImagesIntoMemory(items)
  }, [])

  return (
    <Wrapper
      className={showAlternativeText ? "-alternative-text" : ""}
      id="slideshow"
    >
      {!showAlternativeText && (
        <Background tint={!!featured_image_tint}>
          {imageSharp && (
            <Link to={url} target={urlTarget}>
              <Image
                type="fluid"
                media={featured_media}
                size="wordpress_2048x2048"
                sizes={getSizes({default: '100vw'})}
              />
            </Link>
          )}
        </Background>
      )}
      <StyledContainer>
        {showAlternativeText && (
          <AlternativeTextContainer>
            <AlternativeText fitVertically={true}>
              {alternativeText}
            </AlternativeText>
          </AlternativeTextContainer>
        )}
        <ContentWrapper>
          <Content>
            <Title>
              <Link to={url} target={urlTarget} dangerouslySetInnerHTML={{ __html: title }} />
            </Title>
            <Meta>
              {author && (
                <MetaAuthor>
                  <Link to={author.path}>{author.name}</Link>
                </MetaAuthor>
              )}
              {article_collaboration && (
                <MetaCollaboration>{article_collaboration}</MetaCollaboration>
              )}
              {category && !article_collaboration && (
                <MetaCategory>
                  <Link to={category.path}>{category.name}</Link>
                </MetaCategory>
              )}
            </Meta>
            {/*<Description dangerouslySetInnerHTML={{ __html: excerpt }} />*/}
          </Content>
          <Pagination>
            {items.map((item, index) => (
              <PaginationItem
                className={index === currentIndex ? "-active" : ""}
                key={index}
                onClick={() => {
                  changeSlide(index)
                }}
              >
                {"0" + (index + 1)}
              </PaginationItem>
            ))}
          </Pagination>
        </ContentWrapper>
      </StyledContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 400px;
  overflow: hidden;
  margin-top: -70px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  color: #fff;

  &.-alternative-text {
    color: inherit;
  }

  ${media.tablet} {
    height: 600px;
    margin-bottom: 56px;
  }

  ${media.desktop} {
    height: 70vh;
  }
`

const Background = styled.div`
  pointer-events: all;

  &,
  &::before,
  &::after {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  ${p =>
    p.tint &&
    css`
      &::before,
      &::after {
        content: "";
        pointer-events: none;
      }

      &::before {
        background: rgba(0, 0, 0, 0.2);
      }

      &::after {
        opacity: 0.5;
        height: 50%;
        top: 50%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
      }
    `}
`

const Image = styled(Media)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding-bottom: 25px;
  position: relative;
  z-index: 1;
  pointer-events: none;

  ${media.desktop} {
    padding-bottom: 74px;
  }
`

const AlternativeTextContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
`

const AlternativeText = styled(FlexibleHeading)`
  transform-origin: center center;
  pointer-events: none;
`

const ContentWrapper = styled.div`
  ${media.desktop} {
    display: flex;
    justify-content: space-between;
  }
`

const Content = styled.div`
  ${media.desktop} {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 102px;
  }
`

const Title = styled.h2`
  max-width: 750px;
  margin-bottom: 21px;
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  pointer-events: all;

  br {
    display: none;
  }

  ${media.tablet} {
    margin-bottom: 12px;
    font-size: 30px;
  }
`

const Description = styled.p`
  max-width: 455px;
  max-height: 50px;
  overflow: hidden;
  font-size: 15px;
  line-height: 20px;
  font-family: ${p => p.theme.font.secondary};

  ${media.tablet} {
    font-size: 17px;
    line-height: 25px;
  }
`

const Meta = styled.p`
  margin-bottom: 21px;
  font-size: 14px;
  line-height: 18px;

  > * {
    display: inline-block;
    opacity: 0.5;
    margin-right: 16px;
    pointer-events: all;

    ${Wrapper}.-alternative-text &:not(:first-child) {
      opacity: 1;
      color: var(--subtext-color, ${p => p.theme.color.grey});
    }
  }

  ${media.tablet} {
    margin-bottom: 12px;
  }
`

const MetaAuthor = styled.span`
  opacity: 1;
  font-weight: 600;
`

const MetaCollaboration = styled.span``
const MetaCategory = styled.span``

const Pagination = styled.ul`
  display: flex;
  margin-top: 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  ${media.desktop} {
    margin-top: 30px;
    font-size: 30px;
    line-height: 1;
  }
`

const PaginationItem = styled.li`
  opacity: 0.4;
  margin-right: 20px;
  position: relative;
  cursor: pointer;
  pointer-events: all;
  transition: 0.1s;

  &::before {
    content: "";
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }

  &:hover {
    opacity: 0.8;
  }

  &.-active {
    opacity: 1;
  }

  ${media.desktop} {
    margin-left: 20px;
    margin-right: 0;
  }
`
