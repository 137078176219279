import React, { useState, useRef } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { breakpoints, media } from "src/styles/breakpoints"
import { PrimaryTitle } from "src/components/Typography"
import Container from "src/components/Container"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { get } from "lodash"
import useIntersectionObserver from "src/hooks/useIntersectionObserver"

export default () => {
  const { wordpressAcfOptions } = useStaticQuery(graphql`
    query SubscribeSectionQuery {
      wordpressAcfOptions {
        options {
          home_subscribe {
            image {
              source_url
            }
            title
            cta_text
          }
        }
      }
    }
  `)
  const { image, title, cta_text } = wordpressAcfOptions.options.home_subscribe
  const [imageUrl, setImageUrl] = useState("")
  const wrapper = useRef()

  useIntersectionObserver({
    callback: change => {
      if (change.isIntersecting) {
        setImageUrl(image.source_url)
      }
    },
    elementRef: wrapper,
  })

  return (
    <Wrapper ref={wrapper}>
      <StyledContainer>
        <Header>
          {title && <Title>{title}</Title>}
          {cta_text && (
            <CTA>
              <Link to="/subscribe">{cta_text}</Link>
            </CTA>
          )}
        </Header>
        {image && (
          <Issues>
            <Image src={imageUrl} loading="lazy" />
          </Issues>
        )}
      </StyledContainer>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  margin-bottom: 95px;

  ${media.tablet} {
    margin-top: -5px;
    margin-bottom: 85px;
    overflow: hidden;
  }
`

const StyledContainer = styled(Container)`
  pointer-events: all;

  ${media.desktop} {
    display: flex;
  }
`

const Header = styled.header`
  ${media.desktop} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 275px;
  }
`

const Title = styled(PrimaryTitle)``

const CTA = styled.p`
  position: relative;
  top: 150px;
  z-index: 1;
  font-weight: 600;

  ${media.tablet} {
    top: 256px;
  }

  ${media.desktop} {
    top: -36px;
  }
`

const Issues = styled.div``

const Image = styled.img`
  @media (max-width: ${breakpoints.desktop - 1}px) {
    width: 100% !important;
    height: calc(
      (100vw - ${p => p.theme.sidePadding.tablet}px * 2) * 0.33543
    ) !important;
  }

  @media (max-width: ${breakpoints.tablet - 1}px) {
    height: calc(
      (100vw - ${p => p.theme.sidePadding.phone}px * 2) * 0.33543
    ) !important;
  }

  ${media.desktop} {
    position: relative;
    top: -5px;
  }
`
